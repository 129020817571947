<template>
  <BaseList :headers="headers">
    <template #item="{item}">
      <ListItem :item="item"/>
    </template>
  </BaseList>
</template>

<script>
import ListItem from './list/ListItem'
import BaseList from '@/components/base/BaseList'
import headers from './list/headers'
import watchFullPath from '@/components/mixins/watchFullPath'

export default {
  name: 'List',
  components: { ListItem, BaseList },
  mixins: [watchFullPath],
  data () {
    return {
      headers: headers
    }
  }
}
</script>

<style scoped>

</style>
