export default {
  watch: {
    '$route.fullPath': {
      handler () {
        this.$store.commit('serviceData/setStoryRoute', this.$route.fullPath)
      },
      deep: true,
      immediate: true
    }
  }
}