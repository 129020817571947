<template>
  <tr
    @click="detail(item.id)"
  >
    <td>{{item.id}}</td>
    <td>{{item.name}}</td>
    <td>{{item.address}}</td>
    <td>{{item.region}}</td>
    <td>{{item.city}}</td>
    <td>{{roleListByName(item.role)}}</td>
  </tr>
</template>

<script>
import formatters from '@/components/mixins/formatters'
import listItem from '@/components/mixins/listItem'
import serviceDataComputeds from '@/components/mixins/serviceDataComputeds'

export default {
  mixins: [formatters, listItem, serviceDataComputeds],
  name: 'ListItem'
}
</script>

<style scoped>

</style>
