import { translate } from '@/messages'
export default [
  {
    text: translate('base.id'),
    align: 'start',
    value: 'id'
  },
  {
    text: translate('organization.name'),
    align: 'start',
    value: 'name'
  },
  {
    text: translate('organization.address'),
    align: 'start',
    value: 'address'
  },
  {
    text: translate('organization.region'),
    align: 'start',
    value: 'region'
  },
  {
    text: translate('organization.city'),
    align: 'start',
    value: 'city'
  },
  {
    text: translate('organization.role'),
    align: 'start',
    value: 'role'
  }
]
